// localStorage.js

// Getter function to retrieve a value from LocalStorage
export const getLocalStorageItem = (key: string) => {
  try {
    if (typeof localStorage === "undefined") return null;

    const serializedItem = localStorage.getItem(key);
    if (serializedItem === null) {
      // Return null if the item does not exist in LocalStorage
      return null;
    }
    // Deserialize the item and return it
    return JSON.parse(serializedItem);
  } catch (error: any) {
    // Handle any errors (e.g., parsing error)
    console.error(`Error retrieving item from LocalStorage: ${error.message}`);
    return null;
  }
};

// Setter function to set a value in LocalStorage
export const setLocalStorageItem = (key: string, value: string) => {
  try {
    // Serialize the value before storing it in LocalStorage
    const serializedItem = JSON.stringify(value);
    localStorage.setItem(key, serializedItem);
  } catch (error: any) {
    // Handle any errors (e.g., serialization error)
    console.error(`Error setting item in LocalStorage: ${error.message}`);
  }
};
