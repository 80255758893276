import { createSelector } from "reselect";
import { EOperationOptions, Annotation, CallbackCodes } from "@/globalEnum";
import { ChatState } from "./chatContextSlice";
import {
  areAllChecked,
  areSomeChecked,
  booleanOnlyValues,
  mapReferenceToFb,
  reverseString,
} from "@/lib/utils/math";
import { orderBy } from "lodash";
import _ from "lodash";

// state.{nameOfSlice}
const selectChatContext = (state: any) => state.chatContext;

export const selectCurrentChatThread = createSelector(
  [selectChatContext],
  (chat: ChatContext) => chat.context
);

export const selectCurrentChatId = createSelector(
  [selectChatContext],
  (chat: ChatContext) => chat.chatId
);

export const selectLLM = createSelector([selectChatContext], (chat: ChatState) => chat.LLMModel);

export const selectChatMode = createSelector(
  [selectChatContext],
  (chat: ChatState) => chat.LLMModel.chat_mode
);

export const selectLLMModel = createSelector(
  [selectChatContext],
  (chat: ChatState) => chat.LLMModel.model
);

export const selectCurrentChatContext = createSelector(
  [selectChatContext],
  (chat: ChatContext) => chat.context
);

export const selectCurrentChatTitle = createSelector(
  [selectChatContext],
  (chat: ChatContext) => chat.title ?? ""
);

export const createChatTrigger = createSelector(
  [selectChatContext],
  (chat: ChatState) => chat.createChat
);

export const selectShowDiffCard = createSelector(
  [selectChatContext],
  (chat: ChatState) => chat.showDiffCard
);

export const selectShowBookmark = createSelector(
  [selectChatContext],
  (chat: ChatState) => chat.showBookmark
);

export const selectLatestOperator = createSelector(
  [selectChatContext],
  (chat: ChatContext) => chat.latestOperator
);

export const isAnswerType = createSelector(
  [selectChatContext],
  (chat: ChatContext) => chat.latestOperator === EOperationOptions.AnswerType
);

export const findQNACounterpart = (targetId: string) =>
  createSelector([selectCurrentChatThread], (msg: Message[]) => {
    const partnerId = reverseString(targetId);
    const partnerMessage = msg.find((msg) => msg.messageId === partnerId);
    return partnerMessage;
  });

export const selectLatestMessageId = createSelector([selectChatContext], (chat: ChatContext) => {
  const orderedContext = chat.context;
  return orderedContext[orderedContext.length - 1]?.messageId;
});

export const selectIsFlaggingAnswerType = (targetMessageId: string) =>
  createSelector([selectCurrentChatContext], (chat: Message[]) => {
    const targetMessage = chat.find((msg) => msg.messageId === targetMessageId);
    if (!targetMessage) return false;
    return targetMessage.operation === EOperationOptions.AnswerType;
  });

export const selectIsFlaggingFindType = (targetMessageId: string) =>
  createSelector([selectCurrentChatContext], (chat: Message[]) => {
    const targetMessage = chat.find((msg) => msg.messageId === targetMessageId);
    if (!targetMessage) return false;
    return targetMessage.operation === EOperationOptions.FindType;
  });

export const selectOldResponseMessageId = (resetHead: number) =>
  createSelector([selectChatContext], (chat: ChatContext) => {
    const orderedContext = chat.context;
    return orderedContext[orderedContext.length - resetHead]?.messageId ?? "";
  });

export const selectOldResponseMessageIsQuestion = (resetHead: number) =>
  createSelector([selectChatContext], (chat: ChatContext) => {
    const orderedContext = chat.context;
    return orderedContext[orderedContext.length - resetHead]?.isQuestion;
  });

export const selectFactblockSummary = createSelector(
  [selectChatContext],
  (chat: ChatContext) => chat.factblockSummary
);

export const hideSideMenu = createSelector(
  [selectChatContext],
  (chat: ChatState) => chat.hideSideMenu
);

export const selectFactblockSummaryState = (messageId: string) =>
  createSelector(
    [selectChatContext],
    (chat: ChatContext) =>
      chat.context.find((msg) => msg.messageId === messageId)?.facblockSummaryState
  );

export const selectOriginalFactblockSummary = createSelector(
  [selectChatContext],
  (chat: ChatState) => chat.originalFactBlockSummary
);

export const selectExternalFilterState = createSelector(
  [selectChatContext],
  (chat: ChatState) => chat.findTypeFilter.external
);

export const selectInternalFindTypeFilterState = createSelector(
  [selectChatContext],
  (chat: ChatState) => chat.findTypeFilter.internal
);

export const selectExternalFindTypeFilterState = createSelector(
  [selectChatContext],
  (chat: ChatState) => chat.findTypeFilter.external
);

export const selectExternalFindTypeFilterStateBeta = createSelector(
  [selectChatContext],
  (chat: ChatState) => chat.findTypeFilter.externalSimple
);

export const selectGroupFindTypeFilterState = createSelector(
  [selectChatContext],
  (chat: ChatState) => chat.findTypeFilter.group
);

export const selectExternalAnswerTypeFilterState = createSelector(
  [selectChatContext],
  (chat: ChatState) => chat.answerTypeFilter.external
);

export const selectInternalAnswerTypeFilterState = createSelector(
  [selectChatContext],
  (chat: ChatState) => chat.answerTypeFilter.internal
);

export const selectGroupAnswerTypeFilterState = createSelector(
  [selectChatContext],
  (chat: ChatState) => chat.answerTypeFilter.group
);

// Derivative selector for isFindType
export const selectIsFindType = createSelector(
  [selectLatestOperator],
  (latestOp) => latestOp === EOperationOptions.FindType
);

// Derivative selector for internalDocument
export const selectInternalDocument = createSelector(
  [selectIsFindType, selectInternalFindTypeFilterState, selectInternalAnswerTypeFilterState],
  (isFindType, internalFindType, internalAnswerType) =>
    isFindType ? internalFindType : internalAnswerType
);

// Derivative selector for externalDocument
export const selectExternalDocument = createSelector(
  [selectIsFindType, selectExternalFindTypeFilterState, selectExternalAnswerTypeFilterState],
  (isFindType, externalFindType, externalAnswerType) =>
    isFindType ? externalFindType : externalAnswerType
);

// Derivative selector for groupDocument
export const selectGroupDocument = createSelector(
  [selectIsFindType, selectGroupFindTypeFilterState, selectGroupAnswerTypeFilterState],
  (isFindType, groupFindType, groupAnswerType) => (isFindType ? groupFindType : groupAnswerType)
);

// Checked status selectors
export const selectIsInternalAllChecked = createSelector(
  [selectInternalDocument],
  (internalDocument) => areAllChecked(internalDocument)
);

export const selectIsInternalSomeChecked = createSelector(
  [selectInternalDocument],
  (internalDocument) => areSomeChecked(internalDocument)
);

export const selectIsExternalAllChecked = createSelector(
  [selectExternalDocument],
  (externalDocument) => areAllChecked(externalDocument)
);

export const selectIsExternalSomeChecked = createSelector(
  [selectExternalDocument],
  (externalDocument) => areSomeChecked(externalDocument)
);

// Group checked status selectors
export const selectAreAllGroupDocumentsChecked = createSelector(
  [selectGroupDocument],
  (groupDocument) => areAllChecked(booleanOnlyValues(groupDocument))
);

export const selectAreSomeGroupDocumentsChecked = createSelector(
  [selectGroupDocument],
  (groupDocument) => areSomeChecked(booleanOnlyValues(groupDocument))
);

export const selectAreTargetGroupsChecked = createSelector(
  [selectGroupDocument],
  (groupDocument) => groupDocument.targetGroups?.length > 0
);

// Combined checked status selectors
export const selectInternalToggleAllChecked = createSelector(
  [selectIsInternalAllChecked, selectAreAllGroupDocumentsChecked, selectAreTargetGroupsChecked],
  (isInternalAllChecked, areAllGroupDocumentsChecked, areTargetGroupsChecked) =>
    isInternalAllChecked && areAllGroupDocumentsChecked && areTargetGroupsChecked
);

export const selectInternalToggleSomeChecked = createSelector(
  [selectIsInternalSomeChecked, selectAreSomeGroupDocumentsChecked, selectAreTargetGroupsChecked],
  (isInternalSomeChecked, areSomeGroupDocumentsChecked, areTargetGroupsChecked) =>
    isInternalSomeChecked || areSomeGroupDocumentsChecked || areTargetGroupsChecked
);

export const selectQuestionMessages = createSelector([selectChatContext], (chat: ChatContext) =>
  chat.context.filter((msg) => msg.isQuestion)
);

export const selectAnswerMessages = createSelector([selectChatContext], (chat: ChatContext) => {
  const answerMessages = chat.context.filter((msg) => !msg.isQuestion);
  return orderBy(answerMessages, ["updateTime"], ["asc"]);
});

export const selectAnswerTypeResponseMessages = createSelector(
  [selectChatContext],
  (chat: ChatContext) =>
    chat.context.filter((msg) => !msg.isQuestion && msg.operation === EOperationOptions.AnswerType)
);

export const selectAnswerTypeQuestionMessages = createSelector(
  [selectChatContext],
  (chat: ChatContext) =>
    chat.context.filter((msg) => msg.isQuestion && msg.operation === EOperationOptions.AnswerType)
);

export const selectAnswerTypeMessageContentTuple = createSelector(
  [selectAnswerTypeQuestionMessages, selectAnswerTypeResponseMessages],
  (_questions: Message[], _answers: Message[]) => {
    const answerMap = new Map(_answers.map((answer) => [answer.messageId, answer]));
    let result: { question: string; answer: string }[] = [];

    result = _questions
      .map((question) => {
        const reversedMessageId = question.messageId.split("").reverse().join("");
        const answer = answerMap.get(reversedMessageId);
        const answerContent = answer?.content as AnswerResponse;
        // const combinedAnswer = answerContent?.superLawyerResult
        //   ? answerContent?.answer + " " + JSON.stringify(answerContent?.superLawyerResult)
        //   : answerContent?.answer;

        return {
          question: question.content as string,
          answer: answerContent?.answer,
        };
      })
      .filter(
        (item) => item !== null && item?.answer && item?.answer !== CallbackCodes.INAPT_TOPIC
      );

    return result;
  }
);

export const selectLatestQuestion = createSelector(
  [selectQuestionMessages],
  (questionMessages: Message[]) => {
    if (questionMessages.length === 0) return;
    return questionMessages[questionMessages.length - 1];
  }
);
export const selectLatestAnswerMessageReferences = createSelector(
  [selectAnswerMessages],
  (answerMessages: Message[]) => {
    if (answerMessages.length === 0) return;
    return (answerMessages[answerMessages.length - 1].content as AnswerResponse).reference;
  }
);

export const selectLatestAnswerMessageReusableResults = createSelector(
  [selectAnswerMessages],
  (answerMessages: Message[]) => {
    if (answerMessages.length === 0) return;
    return (answerMessages[answerMessages.length - 1].content as AnswerResponse).reusable_results;
  }
);
export const selectLatestAnswerMessageReusableResultsWithSuperlawyer = createSelector(
  [selectAnswerMessages],
  (answerMessages: Message[]) => {
    if (answerMessages.length === 0) return;

    // Start from the last message and move backward to find the first message with superLawyerResult
    let latestAnswerMessage;
    for (let i = answerMessages.length - 1; i >= 0; i--) {
      const message = answerMessages[i].content as AnswerResponse;
      if (message.superLawyerResult) {
        latestAnswerMessage = message;
        break;
      }
    }

    if (!latestAnswerMessage) return;

    const latestSuperlawyerResponse = latestAnswerMessage.superLawyerResult;

    const combinedReusableResults = {
      ...latestAnswerMessage.reusable_results,
      latestSuperlawyerResponse,
    };

    return combinedReusableResults;
  }
);

export const selectLatestAnswerMessageId = createSelector(
  [selectAnswerMessages],
  (answerMessages: Message[]) => {
    if (answerMessages.length === 0) return "";
    return answerMessages[answerMessages.length - 1].messageId;
  }
);

export const selectLatestAnswerMessageAsk = createSelector(
  [selectAnswerMessages],
  (answerMessages: Message[]) => {
    if (answerMessages.length === 0) return "";
    return answerMessages[answerMessages.length - 1].ask as {
      factagora: boolean;
      superlawyer: boolean;
    };
  }
);

export const selectLatestAnswerMessageChatmode = createSelector(
  [selectAnswerMessages],
  (answerMessages: Message[]) => {
    if (answerMessages.length === 0) return "";
    return answerMessages[answerMessages.length - 1].chatMode;
  }
);

export const isLatestMessageId = (targetId: string) =>
  createSelector([selectLatestMessageId], (latestMessageId: string) => {
    if (!latestMessageId) return false;
    return targetId === latestMessageId;
  });

export const activeMessageId = createSelector(
  [selectChatContext],
  (chat: ChatState) => chat.activeMessage
);

export const selectRegeneratedAnswers = (originalMessageId: string) =>
  createSelector([selectChatContext], (chat: ChatContext) =>
    chat.context.filter((msg) => msg.formerId === originalMessageId && !msg.isQuestion)
  );

export const selectMessageQNAById = (targetId: string) =>
  createSelector([selectCurrentChatThread], (msg: Message[]) => {
    const responseId = reverseString(targetId);
    const questionMessage = msg.find((msg) => msg.messageId === responseId);
    const answerMessage = msg.find((msg) => msg.messageId === targetId);
    return { questionMessage, answerMessage };
  });

export const selectSingleMessageById = (targetId: string) =>
  createSelector([selectCurrentChatThread], (msg: Message[]) => {
    return msg.find((msg) => msg.messageId === targetId);
  });

export const selectFindTypePromiseRequestState = createSelector(
  [selectChatContext],
  (state: ChatState) => state.findTypeFactblockAPIState
);

export const selectSuperlawyerPromiseRequestState = createSelector(
  [selectChatContext],
  (state: ChatState) => state.superlawyerAPIState
);
export const selectFactagoraPromiseRequestState = createSelector(
  [selectChatContext],
  (state: ChatState) => state.factagoraAPIState
);
export const selectCasenoteAPIMPromiseRequestState = createSelector(
  [selectChatContext],
  (state: ChatState) => state.findTypeFactblockAPIState.external
);

export const selectInternalDocumentPromiseRequestState = createSelector(
  [selectChatContext],
  (state: ChatState) => state.findTypeFactblockAPIState.internal
);

export const selectReflectTypePromiseRequestState = createSelector(
  [selectChatContext],
  (state: ChatState) => state.reflecTypeFactblockAPIState
);

export const selectReflectFilePromiseRequestState = createSelector(
  [selectChatContext],
  (state: ChatState) => state.reflecTypeFactblockAPIState.file
);

export const selectReflectTextPromiseRequestState = createSelector(
  [selectChatContext],
  (state: ChatState) => state.reflecTypeFactblockAPIState.text
);

export const selectGenerateFBPromiseRequestState = createSelector(
  [selectChatContext],
  (state: ChatState) => state.generateFactblockAPIState
);

export const selectAnswerTypeOperationPromiseRequestState = createSelector(
  [selectChatContext],
  (state: ChatState) => state.answerTypeFactblockAPIState.apiCall
);

export const selectFindTypeOperationPromiseRequestState = createSelector(
  [selectChatContext],
  (state: ChatState) => state.findTypeFactblockAPIState.apiCall
);

export const selectReflectTypeOperationPromiseRequestState = createSelector(
  [selectChatContext],
  (state: ChatState) => state.reflecTypeFactblockAPIState.apiCall
);

export const selectAnswerTypeStreamingActionState = createSelector(
  [selectChatContext],
  (state: ChatState) => state.answerTypeFactblockAPIState.streamingAction
);

export const selectUpdateCurrentChatPromiseRequestState = createSelector(
  [selectChatContext],
  (state: ChatState) => state.updateCurrentChat
);

export const selectAnnotationValue = ({ fbId }: { fbId: string }) =>
  createSelector([selectFactblockSummary], (factblockSummary: FactBlockSummary) => {
    const filteredKeys = Object.keys(factblockSummary).filter((annotation) => {
      const factblockArray = factblockSummary[annotation as Annotation];
      return factblockArray.includes(fbId);
    });
    const annotationKey = filteredKeys.length > 0 ? filteredKeys[0] : Annotation.Null;
    const annotationValue: Annotation | null = annotationKey as Annotation;
    return annotationValue;
  });

export const mapFactblockFromGeneratedReferences = createSelector(
  [selectLatestAnswerMessageReferences],
  (references) => {
    if (!references) return [];
    return mapReferenceToFb(references);
  }
);

/**
 * update answer message with factblock summary
 * intentionally use reverseString and not update question message fbsummary state
 * to get the previous message's factblock summary
 */
export const annotationCounterPerGroup = ({
  messageId,
  fbIdList,
}: {
  messageId: string;
  fbIdList: string[];
}) =>
  createSelector(
    [selectFactblockSummaryState(reverseString(messageId)), selectFactblockSummary],
    (localState, globalState) => {
      const annotationCount = {
        [Annotation.Approval]: 0,
        [Annotation.Negation]: 0,
        [Annotation.Bookmark]: 0,
        [Annotation.Null]: 0,
      };
      const factblockSummary = localState ?? globalState;
      const getAnnotationForFbId = (fbId: string) => {
        if (factblockSummary[Annotation.Approval].includes(fbId)) {
          return Annotation.Approval;
        } else if (factblockSummary[Annotation.Negation].includes(fbId)) {
          return Annotation.Negation;
        } else if (factblockSummary[Annotation.Bookmark].includes(fbId)) {
          return Annotation.Bookmark;
        } else {
          return Annotation.Null;
        }
      };
      fbIdList.forEach((fbId) => {
        const annotation = getAnnotationForFbId(fbId);
        annotationCount[annotation]++;
      });
      return annotationCount;
    }
  );

export const getCallbackData = createSelector(
  [selectChatContext],
  (state: ChatState) => state.callbackData
);
