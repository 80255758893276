import gtag from "react-ga4";

// noun
enum UserActivityCategory {
  Chat = "Chat",
  Annotation = "Annotation",
  Message = "Message",
  Feedback = "Feedback",
}

// verb
enum UserActivity {
  CreateChat = "CreateChat",
  CreateMessage = "CreateMessage",
  CreateFeedback = "CreateFeedback",
  AnswerTypeResponseTime = "AnswerTypeResponseTime",
  Annotate = "Annotate",
  Quote = "Quote",
}

/**
 * 유저별 질의 세션 생성 횟수
 */
export const GACreateChatEvent = ({ chatId, userId }: TCreateChatEvent) => {
  const traceUser = userId.split("@")[0];
  gtag.event(UserActivity.CreateChat, {
    event_category: UserActivityCategory.Chat,
    event_label: chatId,
    event_initiator: traceUser,
    debug_mode: true,
  });
};

/**
 * 유저별 피드백과 유저 쿼리
 */
export const GACreateFeedbackEvent = ({
  userId,
  feedbackId,
  query,
  satisfaction,
  featureOpinion,
  systemOpinion,
}: TCreateFeedbackEvent) => {
  const traceUser = userId.split("@")[0];

  // Start with the non-dynamically named event parameters
  const eventParams: { [key: string]: any } = {
    event_category: UserActivityCategory.Feedback,
    event_label: feedbackId,
    event_initiator: traceUser,
    userQuery: query,
    satisfactionPoint: satisfaction,
    debug_mode: true,
  };

  // Dynamically add feature opinions
  featureOpinion.forEach((opinion, index) => {
    eventParams[`feature_opinion_category_${index + 1}`] = opinion.category;
    eventParams[`feature_opinion_${index + 1}`] = opinion.opinion;
  });

  // Dynamically add system opinions
  systemOpinion.forEach((opinion, index) => {
    eventParams[`system_opinion_category_${index + 1}`] = opinion.category;
    eventParams[`system_opinion_${index + 1}`] = opinion.opinion;
  });

  gtag.event(UserActivity.CreateFeedback, eventParams);
};

/**
 * 유저별 플래깅 횟수
 */
export const GAFBAnnotationEvent = ({
  annotationId,
  userId,
  annotation,
  fbId,
}: TAnnotationEvent) => {
  const traceUser = userId.split("@")[0];
  gtag.event(UserActivity.Annotate, {
    event_category: UserActivityCategory.Annotation,
    event_label: annotationId,
    event_initiator: traceUser,
    annotation,
    fbId,
    debug_mode: true,
  });
};

/**
 * 유저 별 오퍼레이션 횟수
 */
export const GACreateMessageEvent = ({ messageId, userId, operation }: TCreateMessageEvent) => {
  // console.log("GACreateMessageEvent", operation);
  const traceUser = userId.split("@")[0];
  gtag.event(UserActivity.CreateMessage, {
    event_category: UserActivityCategory.Message,
    event_label: messageId,
    event_initiator: traceUser,
    operation,
    debug_mode: true,
  });
};

/**
 * 모든 유저 법률질의 응답시간 수집
 */
export const GACollectAnswerTypeResponseTimeEvent = ({
  responseTime,
}: TCollectResponseTimeEvent) => {
  gtag.event(UserActivity.AnswerTypeResponseTime, {
    event_category: UserActivityCategory.Message,
    response_time: responseTime,
    debug_mode: true,
  });
};

/**
 * 팩트블럭 별 인용 횟수
 * 인용이란 green flagging을 하여 regeneration 했을 때 재생성 된 답변에 그린 플래그로 포함되었던 팩트블럭이 하나 이상인 행위
 */
export const GARegenerateEvent = ({ userId, approvedFbId }: TRegenerateEvent) => {
  const traceUser = userId.split("@")[0];
  gtag.event(UserActivity.Quote, {
    event_category: UserActivityCategory.Message,
    event_label: approvedFbId,
    event_initiator: traceUser,
    debug_mode: true,
  });
};
