export const CasenoteAPIMOutboundMapper = ({
  casenoteApiRes,
  sourceCategory,
}: {
  sourceCategory: PossibleDocumentTypes;
  casenoteApiRes: CasenoteAPIMOutbound[];
}): Source[] => {
  let externalDocumentList: Source[] = [];
  {
    casenoteApiRes.forEach((doc) => {
      const source: Source = {
        index: "",
        namespace: "",
        origin: "casenote",
        sourceTitle: doc.title,
        sourceTag: doc.tag,
        url: doc.url,
        summary: doc.highlight,
        score: "N/A",
        sourceId: doc.url, // url을 sourceId로 사용
        sourceCategory,
        isInternalDocument: false,
        fbIdList: [],
        html: "",
      };
      externalDocumentList.push(source);
    });
  }
  return externalDocumentList;
};
