import { configureStore, ThunkAction, Action, Middleware } from "@reduxjs/toolkit";
import { persistStore, persistReducer, Persistor } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import reducer from "./reducers";
import { GACollectAnswerTypeResponseTimeEvent } from "./utils/event";
import beta from "@/public/version.json";
const versionToNumber = (versionString: string) => {
  return parseInt(
    versionString
      .split(".")
      .map((part) => part.padStart(2, "0"))
      .join(""),
    10
  );
};
// Configure persistence settings
const persistConfig = {
  key: "root",
  storage,
  version: versionToNumber(beta.version),
  whitelist: ["chatContext", "pool", "user"],
  migrate: (state: any, version: number) => {
    const currentVersion = versionToNumber(beta.version);

    if (version !== currentVersion) {
      console.log("Version mismatch! Purging persisted state.");
      return Promise.resolve(null); // Return null to purge the state
    }
    return Promise.resolve(state); // Keep the existing state if versions match
  },
};

const persistedReducer = persistReducer(persistConfig, reducer);

// Define the action timing middleware
const actionTimingMiddleware: Middleware = (store) => (next) => (action: any) => {
  const startTime = performance.now();
  const result = next(action);
  const endTime = performance.now();
  const responseTime = (endTime - startTime).toFixed(2);
  if (process.env.NODE_ENV !== "production") {
    console.log(`Action ${action.type} took ${responseTime} ms`);
  }
  return result;
};

// Function to create and configure the store
export const makeStore = () => {
  const store = configureStore({
    reducer: persistedReducer, // Use the persisted reducer here
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false, // Disable serializable checks for redux-persist
      }).concat(actionTimingMiddleware),
  });

  // Create the persistor object
  const persistor = persistStore(store);

  return { store, persistor };
};

// Infer the type of makeStore
// @ts-ignore
export type AppStore = ReturnType<typeof makeStore>["store"];
export type PersistorType = Persistor;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
