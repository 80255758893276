import { Annotation, DocumentTypeMapKoToEn } from "@/globalEnum";
type FbApiResJson = {
  [key: string]: {
    id: string;
    metadata: {
      created_at: string;
      fb_type: string;
      legal_doc_type: string;
      legal_group: string;
      source: string;
      text: string;
      title: string;
      url: string;
      namespace: string;
      index: string;
    };
    chunk_fbs: Array<{
      id: string;
      score: number;
      metadata: {
        namespace: string;
        index: string;
        text: string;
        fb_type: string;
        page_id: string;
        page_number: number;
        chunk_summary: string;
      };
    }>;
  };
};

export const userGeneratedFactblockMapper =
  (messageId: string, file_name?: string) =>
  (fbApiRes: string): { factblock: FactBlock[]; source: Source[] } => {
    const fbApiResJson: FbApiResJson = JSON.parse(fbApiRes);
    let userGeneratedFactblockList: FactBlock[] = [];
    let source: Source[] = [];
    Object.keys(fbApiResJson).map((key) => {
      const data = fbApiResJson[key];

      data.chunk_fbs.forEach((chunk) => {
        const factBlock: FactBlock = {
          index: chunk.metadata.index,
          namespace: chunk.metadata.namespace,
          fbId: chunk.id,
          fbTag: [],
          sourceId: data.id,
          messageId,
          origin: data.metadata.source,
          userId: "", // Assuming we don't have this info
          isAIGenerated: false,
          shortSummary: chunk.metadata.chunk_summary,
          score: Math.floor(chunk.score * 100).toString(),
          annotation: Annotation.Approval,
          originalContent: chunk.metadata.text,
          legalDocType: DocumentTypeMapKoToEn[data.metadata.legal_doc_type],
          fbType: chunk.metadata.fb_type as PossibleFbTypes,
          pageNo: chunk.metadata.page_number.toString(),
        };
        userGeneratedFactblockList.push(factBlock);
      });
      const _source: Source = {
        sourceId: data.id,
        sourceCategory: DocumentTypeMapKoToEn[data.metadata.legal_doc_type] ?? "Others",
        sourceTitle: file_name ?? data.metadata.title,
        sourceTag: [],
        summary: data.metadata.text,
        url: data.metadata.url,
        fbIdList: userGeneratedFactblockList.map((fb) => fb.fbId),
        html: "",
        isInternalDocument: false,
        origin: data.metadata.source,
        namespace: data.metadata.namespace,
        index: data.metadata.index,
        score: "1",
      };
      source.push(_source);
    });

    console.log("userGeneratedFactblockList", userGeneratedFactblockList, source);
    return { factblock: userGeneratedFactblockList, source };
  };
