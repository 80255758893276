import { combineReducers } from "redux";
import chatContextSlice from "./features/chat/chatContextSlice";
import factblockSlice from "./features/factblock/factblockSlice";
import poolSlice from "./features/pool/poolSlice";
import userSlice from "./features/user/userSlice";
import deepVerifySlice from "./features/deepverify/deepverifySlice";

const reducer = combineReducers({
  chatContext: chatContextSlice,
  factblock: factblockSlice,
  pool: poolSlice,
  user: userSlice,
  deepverify: deepVerifySlice,
});

export default reducer;
