export enum JudgmentSearchCondition {
  All = "All",
  Order = "Order",
  VerdictSummary = "VerdictSummary",
  Judge = "Judge",
  CaseNumber = "CaseNumber",
  CaseName = "CaseName",
  ExclusionWord = "ExclusionWord",
}

export enum Court {
  SupremeCourt = "SupremeCourt",
  HighCourt = "HighCourt",
  DistrictCourt = "DistrictCourt",
  ConstitutionalCourt = "ConstitutionalCourt",
}

export enum CaseType {
  Civil = "Civil",
  Criminal = "Criminal",
  Administrative = "Administrative",
  Patent = "Patent",
  Family = "Family",
}

export enum LitigationResult {
  PlaintiffDefeat = "PlaintiffDefeat",
  PlaintiffPartialVictory = "PlaintiffPartialVictory",
  PlaintiffVictory = "PlaintiffVictory",
  AppealDismissed = "AppealDismissed",
  AppealRejected = "AppealRejected",
  Remand = "Remand",
  Dismissal = "Dismissal",
  Imprisonment = "Imprisonment",
  Detention = "Detention",
  Fine = "Fine",
  Acquittal = "Acquittal",
  SuspendedSentence = "SuspendedSentence",
}
