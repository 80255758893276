import { Annotation, DocumentTypeMapKoToEn } from "@/globalEnum";

type FbApiResJsonItem = {
  fb_created: boolean;
  fb_retrieved?: boolean;
  id?: string;
  score?: number;
  child_fbs?: Array<{
    id: string;
    score: number;
    metadata: {
      fb_type: "page";
      html_ids: string[];
      text: string;
      namespace: string;
      index: string;
    };
  }>;
  metadata?: {
    fb_type: "document";
    source: string;
    text: string;
    legal_doc_type: string;
    legal_group: string;
    url: string;
    namespace: string[];
    index: string;
  };
};

type FbApiResJson = {
  [key: string]: FbApiResJsonItem;
};

export const casenoteFactblockMapper =
  (messageId: string) =>
  (fbApiRes: string): FactBlock[] => {
    const fbApiResJson: FbApiResJson = JSON.parse(fbApiRes);
    let externalFactblockList: FactBlock[] = [];
    Object.keys(fbApiResJson).forEach((key) => {
      const parent = fbApiResJson[key];

      parent.child_fbs?.forEach((rawPageFb) => {
        const factBlock: FactBlock = {
          fbId: rawPageFb.id,
          namespace: rawPageFb.metadata.namespace, // Add namespace
          index: rawPageFb.metadata.index, // Add index
          sourceId: key,
          messageId,
          userId: "", // Assuming we don't have this info
          origin: parent.metadata?.source ?? "casenote",
          fbTag: [
            parent.metadata?.fb_type ?? "",
            parent.metadata?.source ?? "",
            DocumentTypeMapKoToEn[parent.metadata?.legal_doc_type ?? ""],
          ],
          created: parent.fb_created,
          legalDocType: DocumentTypeMapKoToEn[parent.metadata?.legal_doc_type ?? ""],
          fbType: rawPageFb.metadata.fb_type,
          retrieved: parent.fb_retrieved,
          isAIGenerated: true,
          shortSummary: rawPageFb.metadata.text,
          annotation: Annotation.Null,
          originalContent: rawPageFb.metadata.html_ids.join(","),
          score: Math.floor(rawPageFb.score * 100).toString(),
        };
        externalFactblockList.push(factBlock);
      });
    });

    return externalFactblockList;
  };
