import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialPoolState: TPoolState = {
  factblocks: [],
  sources: [],
  myChats: [],
  needFBGen: [],
  newEntry: "",
};

export const factblockSlice = createSlice({
  name: "pool",
  initialState: initialPoolState,
  reducers: {
    upsertSourceListToPool(state: typeof initialPoolState, action: PayloadAction<Source[]>) {
      action.payload.forEach((source) => {
        const sourceIndex = state.sources.findIndex((s) => s.sourceId === source?.sourceId);
        const sourceExists = sourceIndex >= 0;
        sourceExists ? (state.sources[sourceIndex] = source) : state.sources.push(source);
      });
    },
    updateSingleSourceToPool(state: typeof initialPoolState, action: PayloadAction<Source>) {
      const target = action.payload;
      const sourceIndex = state.sources.findIndex((s) => s.sourceId === target.sourceId);
      const sourceExists = sourceIndex >= 0;
      sourceExists ? (state.sources[sourceIndex] = target) : state.sources.push(target);
    },
    toggleFbExistancePool(state: typeof initialPoolState, action: PayloadAction<string>) {
      const sourceId = action.payload;
      const sourceIndex = state.needFBGen.findIndex((s) => s === sourceId);
      const sourceExists = sourceIndex >= 0;
      sourceExists
        ? (state.needFBGen = state.needFBGen.splice(sourceIndex, 1))
        : state.needFBGen.push(sourceId);
    },
    updateSourceFbIdList: (
      state,
      action: PayloadAction<{
        sourceId: string;
        fbIdList: string[];
      }>
    ) => {
      const target = action.payload;
      const sourceIndex = state.sources.findIndex((s) => s.sourceId === target.sourceId);
      const sourceExists = sourceIndex >= 0;
      if (sourceExists) {
        const sourceFbIdSet = new Set(state.sources[sourceIndex].fbIdList);
        target.fbIdList.forEach((id) => sourceFbIdSet.add(id));
        state.sources[sourceIndex].fbIdList = Array.from(sourceFbIdSet);
      }
    },
    upsertFactblockListToPool(state: typeof initialPoolState, action: PayloadAction<FactBlock[]>) {
      action.payload.forEach((factblock) => {
        const factblockIndex = state.factblocks.findIndex((s) => s.fbId === factblock.fbId);
        const factblockExists = factblockIndex >= 0;
        factblockExists
          ? (state.factblocks[factblockIndex] = factblock)
          : state.factblocks.push(factblock);
      });
    },
    updateSingleFactblockToPool(state: typeof initialPoolState, action: PayloadAction<FactBlock>) {
      const target = action.payload;
      const factblockIndex = state.factblocks.findIndex((s) => s.fbId === target.fbId);
      const factblockExists = factblockIndex >= 0;
      factblockExists ? (state.factblocks[factblockIndex] = target) : state.factblocks.push(target);
    },
    upsertMyChatsToPool(state: typeof initialPoolState, action: PayloadAction<ChatContext[]>) {
      action.payload.forEach((chat) => {
        const index = state.myChats.findIndex((s) => s.chatId === chat.chatId);
        const chatExists = index >= 0;
        chatExists ? (state.myChats[index] = chat) : state.myChats.push(chat);
      });
    },
    deleteMyChatsFromPool(state: typeof initialPoolState, action: PayloadAction<string>) {
      const target = state.myChats.find((s) => s.chatId === action.payload);
      state.myChats = state.myChats.filter((c) => c.chatId !== target?.chatId);
    },
    updateNewChat: (state, action: PayloadAction<string>) => {
      state.newEntry = action.payload;
    },
    updateNewChatWithNewTitle: (
      state,
      action: PayloadAction<{ chatId: string; title: string }>
    ) => {
      const { chatId, title } = action.payload;
      const index = state.myChats.findIndex((s) => s.chatId === chatId);
      const chatExists = index >= 0;
      chatExists && (state.myChats[index].title = title);
    },
  },
});

export const {
  upsertSourceListToPool,
  upsertFactblockListToPool,
  updateSingleSourceToPool,
  updateSingleFactblockToPool,
  upsertMyChatsToPool,
  deleteMyChatsFromPool,
  updateSourceFbIdList,
  toggleFbExistancePool,
  updateNewChat,
  updateNewChatWithNewTitle,
} = factblockSlice.actions;
export default factblockSlice.reducer;
