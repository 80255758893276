import { RootState } from "@/lib/store";
import { createSelector } from "reselect";
import { selectFactblockSummary, selectFactblockSummaryState } from "@/lib/features/chat/selectors";
import _ from "lodash";
import { Annotation } from "@/globalEnum";
import { generateAnnotationsWithFactblocks } from "@/lib/utils/math";
import { selectBookmark } from "../user/selectors";
import { dummyFactblockData } from "@/dummyFactblock";

// state.{nameOfSlice}
const selectPoolState = (state: RootState) => state.pool;

// Source related selectors
export const selectPooledSources = createSelector([selectPoolState], (f) => f.sources);
export const selectPooledSourceIDs = createSelector([selectPoolState], (f) =>
  f.sources.map((s) => s.sourceId)
);
export const selectSourceOfFactblock = (fbId: string) =>
  createSelector([selectPoolState], (f) =>
    f.sources.find((s) => s.fbIdList.includes(fbId) !== undefined)
  );

export const selectRelevantSourcesFromCache = (targetIds: string[]) =>
  createSelector([selectPoolState], (f) =>
    f.sources.filter((source: Source) => targetIds.includes(source.sourceId))
  );

export const manageableSources = createSelector([selectPoolState], (f) => f.sources);

// Factblock related selectors
export const selectPooledFactblocks = createSelector([selectPoolState], (f) => f.factblocks ?? []);

/**
 * 현 정책상 어노테이션은 chunk와 page레벨에서 가능하다.
 * @param targetId
 * @returns fb_type:string
 */
export const selectFbTypeFromCache = (targetId: string) =>
  createSelector([selectPooledFactblocks], (f) => {
    const cachedFb = f.find((fb: FactBlock) => targetId === fb.fbId);
    if (!cachedFb) return null;
    const searchResult: TFactblockLocation = {
      fb_type: cachedFb.fbType ?? "chunk",
      chunk_id: cachedFb.fbId ?? "",
      namespace: cachedFb.namespace ?? "",
      pinecone_index: cachedFb.index ?? "",
      partial_answer: cachedFb.partialAnswer,
    };
    return searchResult;
  });

export const selectBookmarkedFactblocksToChat = createSelector(
  [selectBookmark],
  (bmFbId: string[]) => {
    let result: TFactblockLocation[] = [];
    // todo - 내가 만들어서 upsert한 팩트블럭에 대해서는 논리적으로 별개의 pool로 상태관리를 함이 재사용성이 높을 것
    const relevantFactblocks = dummyFactblockData.filter((d) => bmFbId.includes(d.fbId));
    relevantFactblocks.map((r) => {
      const mapped: TFactblockLocation = {
        fb_type: r.fbType,
        chunk_id: r.fbId,
        namespace: r.namespace,
        pinecone_index: r.index,
      };
      result.push(mapped);
    });
    return result;
  }
);

export const selectChunkFactblocksFromCache = (targetSourceId: string) =>
  createSelector([selectPoolState], (f) => {
    const cachedFbIdList = f.sources.find((f) => f.sourceId === targetSourceId)?.fbIdList;
    if (!cachedFbIdList) return [];
    return f.factblocks.filter((fb: FactBlock) => {
      return (
        targetSourceId === fb.sourceId && fb.fbType === "chunk" && cachedFbIdList.includes(fb.fbId)
      );
    });
  });

export const manageableFactblocks = createSelector(
  [selectFactblockSummary, selectPooledFactblocks, manageableSources],
  (factblockSummary: FactBlockSummary, factblocks: FactBlock[], source: Source[]) => {
    console.log("manageableFactblocks", factblockSummary, factblocks, source);
    type TExtendedFactblock = FactBlock & { sourceCategory: string };

    const result: Partial<Record<Annotation, TExtendedFactblock[]>> = {
      [Annotation.Approval]: [],
      [Annotation.Bookmark]: [],
      [Annotation.Negation]: [],
    };
    Object.values(Annotation).forEach((annotation) => {
      const targetFactblocks: FactBlock[] = factblocks.filter((factblock) =>
        factblockSummary[annotation]?.includes(factblock.fbId)
      );

      targetFactblocks.forEach((targetFactblock) => {
        const targetSource: Source = source.find((s) => s.sourceId === targetFactblock.sourceId)!;

        const extendedFactblock: TExtendedFactblock = {
          ...targetFactblock,
          sourceCategory: targetSource.sourceCategory,
        };

        result[annotation]?.push(extendedFactblock);
      });
    });

    return result;
  }
);

export const annotatedFactblocks = (messageId: string) =>
  createSelector(
    [
      selectPoolState,
      selectFactblockSummaryState(messageId),
      selectFactblockSummary,
      manageableSources,
    ],
    (
      poolState: TPoolState,
      localFBSummary: FactBlockSummary | undefined,
      globalFBSummary: FactBlockSummary,
      sources: Source[]
    ) => {
      const factblockSummary = localFBSummary ?? globalFBSummary;
      return generateAnnotationsWithFactblocks(poolState, factblockSummary, sources);
    }
  );

export function compareFactBlockSummaries(dbData: FactBlockSummary, rtkData: FactBlockSummary) {
  const differences: Record<keyof FactBlockSummary, string> = {};
  for (const key in dbData) {
    const dbCount = dbData[key as keyof FactBlockSummary].length;
    const rtkCount = rtkData[key as keyof FactBlockSummary].length;
    const difference = rtkCount - dbCount;

    differences[key as keyof FactBlockSummary] = difference.toString();
    if (difference > 0) {
      differences[key as keyof FactBlockSummary] = "+" + difference.toString();
    }
  }

  return differences;
}

export const selectPooledChats = createSelector([selectPoolState], (f) => {
  return _.orderBy(f.myChats, ["updateTime"], ["desc"]);
});

export const selectFBExistanceSourcePool = createSelector([selectPoolState], (f) => f.needFBGen);
export const selectMyFBExistanceFromSourcePool = (target: string) =>
  createSelector([selectPoolState], (f) => f.needFBGen.find((s) => s === target) !== undefined);

export const selectMyChats = createSelector([selectPoolState], (poolState) => {
  return _.orderBy(
    poolState.myChats.filter((chat) => chat.chatId.length > 0),
    ["updateTime"],
    ["desc"]
  );
});

export const newChatId = createSelector([selectPoolState], (poolState) => {
  return poolState.newEntry;
});
