import { createSelector } from "reselect";

// state.{nameOfSlice}
export const selectDeepVerifyContext = (state: any) => state.deepverify;
export const selectCurrentQuery = createSelector(
  [selectDeepVerifyContext],
  (deepverify: any) => deepverify.query
);

export const selectCurrentClaim = createSelector(
  [selectDeepVerifyContext],
  (deepverify: any) => deepverify.claim
);

export const selectCurrentPolicies = createSelector(
  [selectDeepVerifyContext],
  (deepverify: any) => deepverify.policies
);

export const selectFinalizedPolicies = createSelector(
  [selectDeepVerifyContext],
  (deepverify: any) => deepverify.policies.primary_policies
);

export const selectPoliciesPending = createSelector(
  [selectDeepVerifyContext],
  (deepverify: any) => deepverify.pending
);
