import { RootState } from "@/lib/store";
import { Relation } from "@/globalEnum";

// state.{nameOfSlice}
export const selectUserState = (state: RootState) => state.user;
export const selectUserIdState = (state: RootState) => state.user.userId;
export const selectUserIdentityState = (state: RootState) => state.user.email;
export const selectBookmark = (state: RootState) => state.user.bookmarkedFbIdList;
export const selectMenuRouter = (state: RootState) => state.user.menuRouter;
export const selectParticipatingRelationNumber = (fbId: string) => (state: RootState) =>
  state.user.customRelations
    ?.filter((r) => r.destinationFbId === fbId || r.sourceFbId === fbId)
    .filter((r) => r.relationType !== Relation.Null).length;

export const selectTemporaryConnectionStatus = (state: RootState) => state.user.connectionStatus;
