import { Annotation } from "@/globalEnum";

type FbApiResJson = {
  [key: string]: Array<{
    id: string;
    legal_doc_type: string;
    fb: {
      text: string;
      fb_type: "chunk";
      source: string;
      doc_id: string;
      legal_doc_type: string;
      title: string;
      url: string;
      document_metadata: {
        submission_date: string;
      };
      page_id: string;
      page_number: number;
      chunk_id: string;
      chunk_summary: string;
    };
    score: number;
  }>;
};
//retrieve_internal_chunkfbs
export const internalFactblockMapper =
  (params: { messageId: string; index: string; namespace: string }) =>
  (fbApiRes: string): FactBlock[] => {
    const fbApiResJson: FbApiResJson = JSON.parse(fbApiRes);
    let internalFactblockList: FactBlock[] = [];
    const { messageId, index, namespace } = params;
    Object.keys(fbApiResJson).map((key) => {
      fbApiResJson[key].forEach((data) => {
        const factBlock: FactBlock = {
          fbId: data.fb.chunk_id,
          namespace,
          index,
          sourceId: data.id,
          origin: data.fb.source,
          messageId,
          userId: "", // Assuming we don't have this info
          fbTag: [],
          isAIGenerated: true,
          shortSummary: data.fb.chunk_summary,
          pageNo: data.fb.page_number.toString(),
          score: Math.floor(data.score * 100).toString(),
          annotation: Annotation.Null,
          originalContent: data.fb.text,
          legalDocType: data.fb.legal_doc_type,
          fbType: data.fb.fb_type,
        };

        internalFactblockList.push(factBlock);
      });
    });
    return internalFactblockList;
  };
