import { Annotation, DocumentTypeMapKoToEn } from "@/globalEnum";

type NewFbApiResJson = {
  id: string;
  child_fbs: Array<{
    id: string;
    score: number;
    metadata: {
      fb_type: "page";
      html_ids: string[];
      text: string;
      namespace: string;
      index: string;
    };
  }>;
  metadata: {
    fb_type: "document";
    source: string;
    text: string;
    legal_doc_type: string;
    legal_group: string;
    url: string;
    namespace: string[];
    index: string;
  };
};

export const generatedFactblockMapper =
  (messageId: string) =>
  (fbApiRes: string): FactBlock[] => {
    const fbApiResJson: NewFbApiResJson = JSON.parse(fbApiRes);
    let generatedFactblockList: FactBlock[] = [];
    console.log(fbApiResJson);
    const parent = fbApiResJson;

    parent.child_fbs.forEach((rawPageFb) => {
      const factBlock: FactBlock = {
        fbId: rawPageFb.id,
        namespace: rawPageFb.metadata.namespace,
        index: rawPageFb.metadata.index,
        sourceId: parent.metadata.url,
        messageId,
        userId: "",
        fbTag: [
          parent.metadata.fb_type,
          parent.metadata.source,
          DocumentTypeMapKoToEn[parent.metadata.legal_doc_type],
        ],
        origin: parent.metadata.source,
        legalDocType: DocumentTypeMapKoToEn[parent.metadata.legal_doc_type],
        fbType: rawPageFb.metadata.fb_type,
        isAIGenerated: false,
        shortSummary: rawPageFb.metadata.text,
        annotation: Annotation.Null,
        originalContent: rawPageFb.metadata.html_ids.join(","),
        score: Math.floor(rawPageFb.score * 100).toString(),
      };
      generatedFactblockList.push(factBlock);
    });
    // console.log("generatedFactblockList", generatedFactblockList);
    return generatedFactblockList;
  };
