import { DocumentTypeMapKoToEn } from "@/globalEnum";

export const internalDocumentMapper = (fbApiRes: string): Source[] => {
  const fbApiResJson: { [key: string]: LegalDocument[] } = JSON.parse(fbApiRes);
  let internalDocumentList: Source[] = [];
  {
    Object.keys(fbApiResJson).map((key) => {
      fbApiResJson[key].forEach((doc) => {
        const sourceCategory: PossibleDocumentTypes =
          DocumentTypeMapKoToEn[doc.legal_doc_type] ?? "Others"; // Default to "Others" if not found
        const source: Source = {
          index: doc.fb.index,
          namespace: doc.fb.namespace,
          origin: doc.fb.source,
          sourceId: doc.fb.doc_id,
          sourceCategory,
          isInternalDocument: true,
          sourceTitle: doc.fb.title,
          sourceTag: [], // Add tags if they exist in document_metadata
          summary: doc.fb.text,
          url: doc.fb.url,
          score: Math.floor(doc.score * 100).toString(), // Convert score to percentage
          fbIdList: [],
          html: "",
        };
        internalDocumentList.push(source);
      });
    });
  }

  console.log("internalDocumentList: ", internalDocumentList);
  return internalDocumentList;
};
