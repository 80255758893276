import { AppDispatch } from "@/lib/store";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { selectCurrentClaim, selectCurrentPolicies, selectFinalizedPolicies } from "./selectors";

const initialDeepVerifyState: {
  claim: MedicalClaim;
  query: string;
  policies: PolicyCandidates;
  pending: boolean;
} = {
  claim: {
    patient: "",
    jurisdiction: "",
    treatment: "",
    medical_claim: "",
  },
  query: "",
  policies: { primary_policies: [], recommended_policies: [] },
  pending: false,
};

export const deepVerifySlice = createSlice({
  name: "deepverify",
  initialState: initialDeepVerifyState,
  extraReducers: (builder) => {
    builder.addCase(searchRelevantMedicalPolicies.pending, (state) => {
      state.pending = true;
    });
    builder.addCase(searchRelevantMedicalPolicies.fulfilled, (state) => {
      state.pending = false;
    });
    builder.addCase(searchRelevantMedicalPolicies.rejected, (state) => {
      state.pending = false;
    });
  },
  reducers: {
    updateClaim(state, action: PayloadAction<MedicalClaim>) {
      const claim = action.payload;

      state.claim = {
        ...state.claim,
        ...claim,
      };
    },

    updateQuery(state, action: PayloadAction<string>) {
      state.query = action.payload;
    },
    updatePolicies(state, action: PayloadAction<PolicyCandidates>) {
      state.policies.primary_policies = action.payload.primary_policies;
      state.policies.recommended_policies = action.payload.recommended_policies;
    },
    updatePendingForPoliciesSearch(state, action: PayloadAction<boolean>) {
      state.pending = action.payload;
    },
  },
});
export default deepVerifySlice.reducer;
export const { updateClaim, updateQuery, updatePolicies, updatePendingForPoliciesSearch } =
  deepVerifySlice.actions;

export const parseMedicalClaim = createAsyncThunk<void, string, { dispatch: AppDispatch }>(
  "deepVerifySlice/parseMedicalClaim",
  async (text: string, { dispatch }) => {
    const parsedData = await fetch(`/api/v1/factBlock`, {
      method: "POST",
      body: JSON.stringify({
        requestType: "parse_medical_claim",
        payload: text,
      }),
    });
    const responseData = await parsedData.json();
    dispatch(updateQuery(text));
    dispatch(updateClaim(responseData.data.claim as MedicalClaim));
  }
);

export const searchRelevantMedicalPolicies = createAsyncThunk<
  void,
  MedicalClaim,
  { dispatch: AppDispatch; getState: any }
>("deepVerifySlice/searchRelevantMedicalPolicies", async (claim, { dispatch, getState }) => {
  const searchedData = await fetch(`/api/v1/factBlock`, {
    method: "POST",
    body: JSON.stringify({
      requestType: "search_relevant_medical_policies",
      payload: claim,
    }),
  });
  const responseData = await searchedData.json();
  dispatch(updatePolicies(responseData.data.policies));
});

export const deepverifyMedicalClaim = createAsyncThunk<
  boolean,
  string,
  { dispatch: AppDispatch; getState: any }
>("deepVerifySlice/deepverifyMedicalClaim", async (email, { dispatch, getState }) => {
  const claim = selectCurrentClaim(getState());
  const policies = selectFinalizedPolicies(getState()).map((p: Policy) => ({
    policy_name: p.title,
    summary: p.summary,
    url: p.metadata.url ?? "",
    guide: p.guide ?? "",
  }));

  console.log("deepverify_medical_claim", { claim, policies, email });

  const response = await fetch(`/api/v1/factBlock`, {
    method: "POST",
    body: JSON.stringify({
      requestType: "deepverify_medical_claim",
      payload: { deepverify: { claim, policies, email } },
    }),
  });
  console.log("deepverify_medical_claim response:: ", response);
  return response.ok
});
