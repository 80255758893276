import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialFactBlockState: FactBlock & { sourceTitle: string } = {
  fbId: "",
  sourceId: "",
  sourceTitle: "",
  messageId: "",
  userId: "",
  fbTag: [],
  isAIGenerated: false,
  shortSummary: "",
  score: "error",
  annotation: {},
  originalContent: "",
  origin: "",
  namespace: "",
  index: "",
};

export const factblockSlice = createSlice({
  name: "factblock",
  initialState: initialFactBlockState,
  reducers: {
    createFactblockInstance(state, action: PayloadAction<FactBlock & { sourceTitle: string }>) {
      state.fbId = action.payload.fbId;
      state.sourceId = action.payload.sourceId;
      state.sourceTitle = action.payload.sourceTitle;
      state.messageId = action.payload.messageId;
      state.userId = action.payload.userId;
      state.fbTag = action.payload.fbTag;
      state.isAIGenerated = action.payload.isAIGenerated;
      state.shortSummary = action.payload.shortSummary;
      state.pageNo = action.payload.pageNo;
      state.score = action.payload.score;
      state.annotation = action.payload.annotation;
      state.originalContent = action.payload.originalContent;
      state.origin = action.payload.origin;
      state.namespace = action.payload.namespace;
      state.index = action.payload.index;
    },
  },
});

export const { createFactblockInstance } = factblockSlice.actions;
export default factblockSlice.reducer;
