import { Annotation, InternalDocumentType } from "@/globalEnum";

export const dummyFactblockData = [
  {
    fbId: "fb001",
    sourceId: "src001",
    messageId: "msg001",
    userId: "admin",
    fbTag: ["legal", "case", "document"],
    isAIGenerated: true,
    title: "Summary of Legal Case Findings",
    shortSummary: "Summarizes the main points of a legal case.",
    annotation: Annotation.Null,
    originalContent: "The court found the defendant liable for breach of contract...",
    pageNo: "12",
    created: true,
    retrieved: false,
    origin: "file",
    namespace: "legal_case_docs",
    index: "case_law_index_2023",
    fbType: "chunk" as PossibleFbTypes,
    legalDocType: InternalDocumentType.LitigationDocument,
    score: "85",
    partialAnswer: {
      answer: "The defendant was found liable.",
      rationale: "The judgment outlines the breach of contract by the defendant.",
    },
  },
  {
    fbId: "fb002",
    sourceId: "src002",
    messageId: "msg002",
    userId: "admin",
    fbTag: ["finance", "contract", "summary"],
    isAIGenerated: false,
    title: "Financial Terms of Agreement Overview",
    shortSummary: "Outlines the financial terms of the agreement.",
    annotation: Annotation.Null,
    originalContent: "The contract stipulates a payment schedule over five years...",
    pageNo: "23",
    created: true,
    retrieved: false,
    origin: "user_file",
    namespace: "contracts",
    index: "financial_contracts_2023",
    fbType: "document" as PossibleFbTypes,
    legalDocType: InternalDocumentType.LitigationDocument,
    score: "90",
    partialAnswer: {
      answer: "Payment schedule is over five years.",
      rationale: "Detailed in section 4 of the contract.",
    },
  },
  {
    fbId: "fb003",
    sourceId: "src003",
    messageId: "msg003",
    userId: "admin",
    fbTag: ["analysis", "market", "report"],
    isAIGenerated: true,
    title: "Market Trends for Past Quarter",
    shortSummary: "Summarizes the market trends for the past quarter.",
    annotation: Annotation.Null,
    originalContent: "The market has seen a significant increase in technology stocks...",
    pageNo: "5",
    created: true,
    retrieved: true,
    origin: "sejong",
    namespace: "market_reports",
    index: "q1_reports_2023",
    fbType: "page" as PossibleFbTypes,
    legalDocType: InternalDocumentType.LitigationDocument,
    score: "88",
    partialAnswer: {
      answer: "Technology stocks are up.",
      rationale: "Due to increased consumer demand.",
    },
  },
  {
    fbId: "fb004",
    sourceId: "src004",
    messageId: "msg004",
    userId: "admin",
    fbTag: ["policy", "government", "update"],
    isAIGenerated: false,
    title: "Recent Government Policy Changes",
    shortSummary: "Details recent changes in government policy.",
    annotation: Annotation.Null,
    originalContent: "The new policy affects import regulations on electronics...",
    pageNo: "7",
    created: false,
    retrieved: true,
    origin: "user_text",
    namespace: "policy_updates",
    index: "gov_policy_2023",
    fbType: "chunk" as PossibleFbTypes,
    legalDocType: InternalDocumentType.LitigationDocument,
    score: "75",
    partialAnswer: {
      answer: "Import regulations have changed.",
      rationale: "New tariffs introduced on electronics.",
    },
  },
  {
    fbId: "fb005",
    sourceId: "src005",
    messageId: "msg005",
    userId: "admin",
    fbTag: ["research", "science", "study"],
    isAIGenerated: true,
    title: "Findings from Recent Scientific Study",
    shortSummary: "Highlights findings from a recent scientific study.",
    annotation: Annotation.Null,
    originalContent: "The study reveals a correlation between diet and longevity...",
    pageNo: "42",
    created: true,
    retrieved: false,
    origin: "casenote",
    namespace: "scientific_studies",
    index: "health_research_2023",
    fbType: "document" as PossibleFbTypes,
    legalDocType: InternalDocumentType.LitigationDocument,
    score: "92",
    partialAnswer: {
      answer: "Diet impacts longevity.",
      rationale: "Evidence shows certain foods extend lifespan.",
    },
  },
  {
    fbId: "fb006",
    sourceId: "src006",
    messageId: "msg006",
    userId: "admin",
    fbTag: ["legal", "evidence", "analysis"],
    isAIGenerated: true,
    title: "Analysis of Legal Case Evidence",
    shortSummary: "Analyzes the evidence presented in a legal case.",
    annotation: Annotation.Null,
    originalContent: "The evidence shows a clear violation of the terms outlined...",
    pageNo: "13",
    created: true,
    retrieved: false,
    origin: "file",
    namespace: "legal_analysis_docs",
    index: "evidence_index_2023",
    fbType: "chunk" as PossibleFbTypes,
    legalDocType: InternalDocumentType.LitigationDocument,
    score: "86",
    partialAnswer: {
      answer: "Violation of terms is evident.",
      rationale: "Evidence supports breach of contract claims.",
    },
  },
  {
    fbId: "fb007",
    sourceId: "src007",
    messageId: "msg007",
    userId: "admin",
    fbTag: ["finance", "report", "annual"],
    isAIGenerated: true,
    title: "Company's Annual Financial Performance",
    shortSummary: "Summarizes the company's annual financial performance.",
    annotation: Annotation.Null,
    originalContent: "The annual report indicates a significant increase in revenue...",
    pageNo: "30",
    created: true,
    retrieved: false,
    origin: "user_file",
    namespace: "financial_reports",
    index: "annual_reports_2023",
    fbType: "document" as PossibleFbTypes,
    legalDocType: InternalDocumentType.LitigationDocument,
    score: "89",
    partialAnswer: {
      answer: "Revenue has increased significantly.",
      rationale: "Detailed in the financial summary section.",
    },
  },
  {
    fbId: "fb008",
    sourceId: "src008",
    messageId: "msg008",
    userId: "admin",
    fbTag: ["policy", "healthcare", "reform"],
    isAIGenerated: true,
    title: "New Healthcare Policy Reforms Explained",
    shortSummary: "Explains the new healthcare policy reforms.",
    annotation: Annotation.Null,
    originalContent: "The reform introduces changes to healthcare funding and access...",
    pageNo: "22",
    created: true,
    retrieved: true,
    origin: "user_text",
    namespace: "policy_documents",
    index: "healthcare_reform_2023",
    fbType: "chunk" as PossibleFbTypes,
    legalDocType: InternalDocumentType.LitigationDocument,
    score: "87",
    partialAnswer: {
      answer: "Healthcare funding has changed.",
      rationale: "Reform impacts funding and access.",
    },
  },
  {
    fbId: "fb009",
    sourceId: "src009",
    messageId: "msg009",
    userId: "admin",
    fbTag: ["research", "technology", "innovation"],
    isAIGenerated: true,
    title: "Advancements in Technology Innovation",
    shortSummary: "Highlights advancements in technology innovation.",
    annotation: Annotation.Null,
    originalContent: "Recent innovations in AI have accelerated technological growth...",
    pageNo: "55",
    created: true,
    retrieved: false,
    origin: "casenote",
    namespace: "tech_research",
    index: "innovation_reports_2023",
    fbType: "document" as PossibleFbTypes,
    legalDocType: InternalDocumentType.LitigationDocument,
    score: "93",
    partialAnswer: {
      answer: "AI advancements are significant.",
      rationale: "Leading to accelerated growth in tech.",
    },
  },
  {
    fbId: "fb010",
    sourceId: "src010",
    messageId: "msg010",
    userId: "admin",
    fbTag: ["market", "trend", "forecast"],
    isAIGenerated: true,
    title: "Forecast of Upcoming Market Trends",
    shortSummary: "Provides a forecast of upcoming market trends.",
    annotation: Annotation.Null,
    originalContent: "The market is expected to shift towards renewable energy sources...",
    pageNo: "60",
    created: true,
    retrieved: false,
    origin: "sejong",
    namespace: "market_forecasts",
    index: "trend_reports_2023",
    fbType: "page" as PossibleFbTypes,
    legalDocType: InternalDocumentType.LitigationDocument,
    score: "91",
    partialAnswer: {
      answer: "Shift towards renewable energy.",
      rationale: "Due to environmental concerns and regulations.",
    },
  },
  {
    fbId: "fb011",
    sourceId: "src011",
    messageId: "msg011",
    userId: "admin",
    fbTag: ["legal", "case", "precedent"],
    isAIGenerated: true,
    title: "Landmark Case Setting New Legal Precedent",
    shortSummary: "Discusses a landmark case setting new legal precedent.",
    annotation: Annotation.Null,
    originalContent: "The Supreme Court's decision alters the interpretation of privacy laws...",
    pageNo: "45",
    created: true,
    retrieved: false,
    origin: "file",
    namespace: "legal_case_docs",
    index: "precedent_cases_2023",
    fbType: "chunk" as PossibleFbTypes,
    legalDocType: InternalDocumentType.LitigationDocument,
    score: "95",
    partialAnswer: {
      answer: "New interpretation of privacy laws.",
      rationale: "Supreme Court decision changes precedent.",
    },
  },
  {
    fbId: "fb012",
    sourceId: "src012",
    messageId: "msg012",
    userId: "admin",
    fbTag: ["environment", "policy", "update"],
    isAIGenerated: false,
    title: "Changes in Environmental Policy",
    shortSummary: "Details changes in environmental policy.",
    annotation: Annotation.Null,
    originalContent: "The new policy introduces stricter emissions standards...",
    pageNo: "28",
    created: false,
    retrieved: true,
    origin: "user_text",
    namespace: "environmental_policies",
    index: "policy_updates_2023",
    fbType: "chunk" as PossibleFbTypes,
    legalDocType: InternalDocumentType.LitigationDocument,
    score: "78",
    partialAnswer: {
      answer: "Emissions standards are stricter.",
      rationale: "Policy aims to reduce pollution.",
    },
  },
  {
    fbId: "fb013",
    sourceId: "src013",
    messageId: "msg013",
    userId: "admin",
    fbTag: ["education", "reform", "policy"],
    isAIGenerated: true,
    title: "Upcoming Educational Reforms Overview",
    shortSummary: "Explains upcoming educational reforms.",
    annotation: Annotation.Null,
    originalContent: "The reform will overhaul the national curriculum to include technology...",
    pageNo: "35",
    created: true,
    retrieved: false,
    origin: "casenote",
    namespace: "education_reforms",
    index: "reform_documents_2023",
    fbType: "document" as PossibleFbTypes,
    legalDocType: InternalDocumentType.LitigationDocument,
    score: "88",
    partialAnswer: {
      answer: "Curriculum includes more technology.",
      rationale: "Reform aims to modernize education.",
    },
  },
];
