import { type ClassValue, clsx } from "clsx";
import _ from "lodash";
import { twMerge } from "tailwind-merge";
import { v4 as uuidv4 } from "uuid";
import {
  KoreanLegalDocType,
  SejongLegalGroupMap,
  DocumentTypeMapEnToKo,
  DocumentTypeMapKoToEn,
  Annotation,
} from "@/globalEnum";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
export const areSomeChecked = (document: Record<string, boolean>) =>
  Object.values(document).some((checked) => checked);

export const areAllChecked = (document: Record<string, boolean>) =>
  Object.values(document).every((checked) => checked);

export const booleanOnlyValues = (mixedObject: any) =>
  Object.fromEntries(
    Object.entries(mixedObject).filter(([_, value]) => typeof value === "boolean")
  ) as Record<string, boolean>;

export const hasTrueNestedValue = (nestedObj: object) => {
  return _.some(nestedObj, (value) => {
    if (typeof value === "object") {
      return _.some(value, (nestedValue) => typeof nestedValue === "boolean" && nestedValue);
    }
    return typeof value === "boolean" && value;
  });
};

export const messageParingIdGenerator = () => {
  const questionId = uuidv4();
  const responseId = reverseString(questionId);
  return { questionId, responseId };
};

export const isReverseMatch = (control: string, treatment: string) => {
  return control.split("").reverse().join("") === treatment;
};

export const reverseString = (str: string) => {
  return str.split("").reverse().join("");
};

export const areTwoObjectsDifferent = (globalState: object, localState: object | undefined) => {
  if (!localState) return false;
  return !_.isEqual(globalState, localState);
};

export const searchPeriodConverter = (customInput?: string) => {
  if (!!customInput) {
    const valueInt = parseInt(customInput);
    const now = new Date();
    const year = now.getFullYear();
    if (valueInt > 0) return new Date(year - valueInt, now.getMonth(), now.getDate()).toISOString();
  }
  return new Date().toISOString();
};

export const getYearFromISOString = (customInput: TSearchPeriod) => {
  return {
    start: new Date(customInput.start).getFullYear().toString(),
    end: new Date(customInput.end).getFullYear().toString(),
  };
};

export function calculateTimeDifference(startDatetimeStr: string): string {
  const givenDatetime = new Date(startDatetimeStr);
  const currentDatetime = new Date();
  const timeDifference = currentDatetime.getTime() - givenDatetime.getTime();

  const minutesPast = Math.floor(timeDifference / (1000 * 60));
  const hoursPast = Math.floor(timeDifference / (1000 * 60 * 60));
  const daysPast = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const monthsPast = Math.floor(daysPast / 30);
  const remainingDays = daysPast % 30;
  const remainingHours = hoursPast % 24;

  const resultParts: string[] = [];

  if (monthsPast > 0) {
    resultParts.push(`${monthsPast} month${monthsPast > 1 ? "s" : ""}`);
  }
  if (remainingDays > 0) {
    resultParts.push(`${remainingDays} day${remainingDays > 1 ? "s" : ""}`);
  }
  if (remainingHours > 0) {
    resultParts.push(`${remainingHours} hour${remainingHours > 1 ? "s" : ""}`);
  }
  if (minutesPast > 0 && monthsPast === 0 && remainingDays === 0 && remainingHours === 0) {
    resultParts.push(`${minutesPast} minute${minutesPast > 1 ? "s" : ""}`);
  }

  return resultParts.join(", ") + " ago";
}

const filterFormatterInternalDocuments = (
  _sejong: Partial<InternalDocuments>,
  _group: TGroupFilterState
): TInternalFindFilter => {
  const sejong: TLegalFilter[] = [];
  const external_db: TLegalFilter[] = [];
  const sejong_selected: TLegalFilter[] = [];
  const user: TLegalFilter[] = [];

  for (const [key, value] of Object.entries(_sejong)) {
    if (value && key === "User") {
      user.push({
        legal_doc_type: SejongLegalGroupMap.미분류,
        legal_group: SejongLegalGroupMap.미분류,
      });
    } else if (value && DocumentTypeMapEnToKo[key]) {
      sejong.push({
        legal_doc_type: DocumentTypeMapEnToKo[key] as KoreanLegalDocType,
        legal_group: SejongLegalGroupMap.미분류,
      });
    }
  }
  let hasTrueGroupDoc = false;
  for (const [key, value] of Object.entries(_group)) {
    if (value && DocumentTypeMapEnToKo[key]) {
      hasTrueGroupDoc = true;
      if (key == "ExternalDB") {
        for (const group of _group.targetGroups) {
          external_db.push({
            legal_doc_type: SejongLegalGroupMap.미분류,
            legal_group:
              Object.keys(SejongLegalGroupMap).find((enumKey) => enumKey === group) ??
              SejongLegalGroupMap.미분류,
          });
        }
        if (hasTrueGroupDoc && _group.targetGroups.length === 0) {
          external_db.push({
            legal_doc_type: SejongLegalGroupMap.미분류,
            legal_group: SejongLegalGroupMap.미분류,
          });
        }
      } else {
        for (const group of _group.targetGroups) {
          sejong_selected.push({
            legal_doc_type: DocumentTypeMapEnToKo[key] as KoreanLegalDocType,
            legal_group:
              Object.keys(SejongLegalGroupMap).find((enumKey) => enumKey === group) ??
              SejongLegalGroupMap.미분류,
          });
        }
      }
    }
  }

  const filters: TInternalFindFilter = {
    sejong: sejong ?? [],
    sejong_selected: sejong_selected ?? [],
    user: user ?? [],
  };

  return filters as TInternalFindFilter;
};

// casenote
const filterFormatterExternalDocuments = (
  _casenote: ExternalDocumentsSimple
): TExternalFindFilter => {
  let casenote: TLegalFilter[] = [];
  for (const [key, value] of Object.entries(_casenote)) {
    if (value) {
      casenote.push({
        legal_doc_type: DocumentTypeMapEnToKo[key] as KoreanLegalDocType,
        legal_group: SejongLegalGroupMap.미분류,
      });
    }
  }
  const filters: TExternalFindFilter = {
    casenote,
  };
  return filters;
};

/**
 * 변경된 필터 정책에 따라 검색되어야 하지 말아야 할 필드들은 key를 제외하고, 전체검색을 원할 때는 직접 모든 조합을 추가한다.
 * @param param0
 * @returns
 */
export const filterSearchParamEmptyKeys = ({
  findInternal,
  internal,
  internalGroup,
  external,
}: {
  findInternal: boolean;
  internal: Partial<InternalDocuments>;
  internalGroup: TGroupFilterState;
  external: ExternalDocumentsSimple;
}): TInternalFindFilter | TExternalFindFilter => {
  const internalfilters: TInternalFindFilter = filterFormatterInternalDocuments(
    internal,
    internalGroup
  );
  const externalFilters: TExternalFindFilter = filterFormatterExternalDocuments(external);
  const filterEmptyKeysWithEmptyArrayAsValue = (
    object: TInternalFindFilter | TExternalFindFilter
  ) => {
    return Object.fromEntries(Object.entries(object).filter(([_, value]) => value.length > 0));
  };

  if (findInternal) {
    let finalInternalFilter: TInternalFindFilter = {
      sejong: [],
      sejong_selected: [],
      user: [],
    };

    finalInternalFilter = filterEmptyKeysWithEmptyArrayAsValue(
      internalfilters
    ) as TInternalFindFilter;

    // console.log("param", finalInternalFilter);
    return finalInternalFilter;
  } else {
    let finalExternalFilter: TExternalFindFilter = {
      casenote: [],
    };

    finalExternalFilter = filterEmptyKeysWithEmptyArrayAsValue(
      externalFilters
    ) as TExternalFindFilter;
    // console.log("param", finalExternalFilter);
    return finalExternalFilter;
  }
};

export function checkNonZeroValues(obj: Record<Annotation, string>) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key) && obj[key as keyof typeof obj] !== "0") {
      return true;
    }
  }
  return false;
}

export const groupByReferenceAndGenerateSource = (
  references: Reference[]
): { sources: Source[]; grouped: GroupedReference[] } => {
  let groupedMap = references?.reduce<Record<string, GroupedReference>>((acc, reference) => {
    const { namespace, doc_id, pinecone_index, chunk_score, page_score, page_number, doc_summary } =
      reference;
    const { title, legal_doc_type, url, source } = reference.metadata;
    const isCasenoteJudgment =
      DocumentTypeMapKoToEn[legal_doc_type] === "Judgment" && namespace === "casenote";
    const key = `${namespace}-${doc_id}-${pinecone_index}`;
    const target_score = source === "casenote" ? page_score : chunk_score;
    const score = isNaN(target_score) ? 0 : target_score;
    if (!acc[key]) {
      acc[key] = {
        sourceId: doc_id,
        sourceCategory: DocumentTypeMapKoToEn[legal_doc_type],
        sourceTitle: isCasenoteJudgment ? url : title,
        summary: doc_summary,
        url: url,
        score,
        fbIdList: [],
        html: "",
        isInternalDocument: source !== "casenote",
        origin: source,
        namespace,
        index: pinecone_index,
        count: 0,
        pages: new Set<number>(),
        max_score: score,
        group_key: key,
      };
    }
    acc[key].count += 1;
    acc[key].pages.add(page_number);
    acc[key].max_score = Math.floor(Math.max(acc[key].max_score, score * 100));
    acc[key].fbIdList.push(reference.chunk_id);
    return acc;
  }, {});

  groupedMap = _(groupedMap)
    .toPairs()
    .orderBy(([, value]) => value.max_score, "desc")
    .fromPairs()
    .value();

  const grouped = Object.values(groupedMap);

  const sources: Source[] = grouped.map(
    ({
      sourceId,
      sourceCategory,
      sourceTitle,
      summary,
      url,
      max_score,
      fbIdList,
      html,
      isInternalDocument,
      origin,
      namespace,
      index,
    }) => ({
      sourceId,
      sourceCategory,
      sourceTitle,
      sourceTag: [],
      summary,
      url,
      score: max_score.toString(),
      fbIdList,
      html,
      isInternalDocument,
      origin,
      namespace,
      index,
    })
  );

  return { sources, grouped };
};

export const mapReferenceToFb = (references: Reference[]): FactBlock[] => {
  const factBlocks: FactBlock[] = references.map((reference) => ({
    fbId: reference.chunk_id,
    sourceId: reference.doc_id,
    messageId: "",
    userId: "",
    fbTag: [],
    isAIGenerated: true,
    shortSummary: reference.chunk_text,
    annotation: Annotation.Null,
    originalContent: "",
    pageNo: reference.page_number?.toString(),
    origin: reference.metadata.source,
    namespace: reference.namespace,
    index: reference.pinecone_index,
    fbType: reference.fb_type as PossibleFbTypes,
    legalDocType: DocumentTypeMapKoToEn[reference.metadata.legal_doc_type],
    score: Math.floor(reference.chunk_score * 100).toString(),
    partialAnswer: reference.partial_answer,
  }));

  return factBlocks;
};

export function getFormattedTimestamp() {
  const date = new Date();
  const highResTime = performance.now(); // High-resolution time in milliseconds with microsecond precision

  // Convert local time to UTC
  const utcYear = date.getUTCFullYear();
  const utcMonth = String(date.getUTCMonth() + 1).padStart(2, "0");
  const utcDay = String(date.getUTCDate()).padStart(2, "0");
  const utcHours = String(date.getUTCHours()).padStart(2, "0");
  const utcMinutes = String(date.getUTCMinutes()).padStart(2, "0");
  const utcSeconds = String(date.getUTCSeconds()).padStart(2, "0");
  const utcMilliseconds = String(date.getUTCMilliseconds()).padStart(3, "0");

  // Adjust UTC time to KST (UTC+9)
  const kstDate = new Date(
    Date.UTC(
      Number(utcYear),
      date.getUTCMonth(),
      Number(utcDay),
      Number(utcHours),
      Number(utcMinutes),
      Number(utcSeconds)
    )
  );
  kstDate.setHours(kstDate.getHours() + 9);

  const year = kstDate.getUTCFullYear();
  const month = String(kstDate.getUTCMonth() + 1).padStart(2, "0");
  const day = String(kstDate.getUTCDate()).padStart(2, "0");
  const hours = String(kstDate.getUTCHours()).padStart(2, "0");
  const minutes = String(kstDate.getUTCMinutes()).padStart(2, "0");
  const seconds = String(kstDate.getUTCSeconds()).padStart(2, "0");
  const milliseconds = String(kstDate.getUTCMilliseconds()).padStart(3, "0");

  const highResMilliseconds = Math.floor(highResTime % 1000);
  const microseconds = String(
    Math.floor((highResTime - highResMilliseconds) * 1000) % 1000
  ).padStart(3, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${microseconds}`;
}

export const generateAnnotationsWithFactblocks = (
  poolState: TPoolState,
  factblockSummary: FactBlockSummary,
  sources: Source[]
): Record<Annotation, any[]> => {
  const sourceMap = sources?.reduce(
    (acc, source) => {
      acc[source.sourceId] = source;
      return acc;
    },
    {} as Record<string, Source>
  );

  return Object.entries(factblockSummary)?.reduce(
    (acc, [annotation, fbIds]) => {
      const chunkFb = poolState.factblocks
        .filter((fb: FactBlock) => fbIds.includes(fb.fbId))
        .map((fb: FactBlock) => {
          const source = sourceMap[fb.sourceId];
          const currentAnnotation = Object.keys(factblockSummary).find((key) =>
            factblockSummary[key].includes(fb.fbId)
          );

          const enrichedFactblock = {
            ...fb,
            sourceTitle:
              source?.sourceTitle || fb.originalContent.slice(0, 20).concat("...") || "Unknown",
            sourceCategory: source?.sourceCategory || "사용자",
            sourceTag: source?.sourceTag || [],
            summary: source?.summary || "",
            url: source?.url || "",
            score: source?.score,
            annotation: currentAnnotation || annotation, // Add current annotation
          };

          return enrichedFactblock;
        });

      return {
        ...acc,
        [annotation]: chunkFb,
      };
    },
    {} as Record<Annotation, any[]>
  );
};
